<template>
  <div>
    <van-nav-bar
      :title="navBarTitle"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <van-form validate-first @failed="onFailed">
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
        v-model="orderUser.username"
        label="商家"
        name="pattern"
        placeholder="请选择商家"
        readonly
      >
        <template #button>
          <van-button size="small" type="primary" @click="showPopup">选择商家</van-button>
        </template>
      </van-field>
      <van-field
        v-model="orderUser.phone"
        label="手机号码"
        name="validator"
        placeholder="请选择厂家"
        readonly
      />
      <van-field
        readonly
        clickable
        name="datetimePicker"
        :value="orderDatetimeStr"
        label="时间选择"
        placeholder="点击选择时间"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          v-model="orderDatetime"
          type="datetime"
          title="选择完整时间"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>

      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
        商品信息
      </van-divider>

      <div ref="container" style="padding-bottom: 35px">
        <van-sticky :container="container" :offset-top="50">
          <van-button type="primary" style="margin-left: 15px; margin-bottom: 10px;" @click="showProductPopup()">
            选择商品
          </van-button>
          <van-button type="primary" style="margin-left: 15px; margin-bottom: 10px;" v-if="orderUser.id !== undefined || orderUser.accountUserUid !== undefined" @click="onSelectOrder()">
            选择订单
          </van-button>
          <van-button type="danger" size="small" style="margin-left: 15px; margin-bottom: 10px;"
                      v-if="editData.data !== undefined" @click="onClickDelete()">删除
          </van-button>
        </van-sticky>
        <van-notice-bar
          v-if="editData.data !== null && editData.data.refundAmount !== undefined && editData.data.refundAmount !== null && editData.data.refundAmount > 0"
          color="#1989fa" background="#ecf9ff" left-icon="info-o">
          退款金额：{{ editData.data.refundAmount }}
        </van-notice-bar>
        <van-notice-bar
          v-if="editData.data !== null && editData.data.mark !== undefined && editData.data.mark !== null && editData.data.mark !== ''"
          color="#1989fa" background="#ecf9ff" left-icon="info-o">
          备注：{{ editData.data.mark }}
        </van-notice-bar>

        <div style="padding-left: 8px; padding-right: 8px; margin-top: 5px; margin-bottom: 5px;"
             v-for="item in selectedProducts" :key="item.id" :name="item.id">
          <ProductInfo
            :product="item"
            @infoEditSku="onSkuGridEdit"
            @deletedData="onLoadData"
            @onRefresh="onLoadData"
          ></ProductInfo>
        </div>
      </div>

      <div style="margin: 16px;">
        <van-submit-bar :price="amount" button-text="提交" :disabled="submitDisabled"
                        @submit="onShowPaymentBeforeSubmit">
          <template #default>
            {{ total }} 零
          </template>
        </van-submit-bar>
      </div>
    </van-form>

    <van-popup v-model="isPopShow" position="bottom" :style="{ height: '40%' }">
      <van-form @submit="onSubmitForOrder">
        <van-cell-group inset>
          <van-field v-model="refundAmount" type="number" label="退款金额"/>
          <van-field
            v-model="mark"
            rows="2"
            autosize
            label="备注"
            type="textarea"
            maxlength="50"
            placeholder="请输入备注"
            show-word-limit
          />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import {
  Cascader,
  CouponCell,
  DatetimePicker,
  Dialog,
  Divider,
  Field,
  Form,
  NavBar,
  NoticeBar,
  Notify,
  Popover,
  Popup,
  Radio,
  RadioGroup,
  Stepper,
  Sticky,
  SubmitBar,
  Tag
} from 'vant';

import * as returnService from "@/api/admin/return";
import {mapState} from 'vuex'
import $store from "@/store";
import ProductInfo from "@/components/product/productInfo.vue"

export default {
  components: {
    ProductInfo,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Stepper.name]: Stepper,
    [SubmitBar.name]: SubmitBar,
    [Divider.name]: Divider,
    [DatetimePicker.name]: DatetimePicker,
    [Sticky.name]: Sticky,
    [Cascader.name]: Cascader,
    [CouponCell.name]: CouponCell,
    [NavBar.name]: NavBar,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Tag.name]: Tag,
    [Popover.name]: Popover,
    [NoticeBar.name]: NoticeBar,
  },
  data() {
    return {
      amount: 0,
      total: null,
      container: null,
      returnUuid: null,
      submitDisabled: false,
      isSelectNew: false,
      navBarTitle: '新增退货',
      editData: {
        data: null
      },
      // 通过 actions 属性来定义菜单选项
      showPicker: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      orderDatetime: new Date(),
      orderDatetimeStr: '',
      isPopShow: false,
      refundAmount: null,
      mark: null
    };
  },
  computed: {
    ...mapState({}),
    // 箭头函数可使代码更简练
    // 传字符串参数 'count' 等同于 `state => state.count`
    // countAlias: 'count',
    orderUser: {
      get() {
        return this.$store.state.order.orderUser
      },
      set(v) {
        this.$store.state.order.orderUser = v
      }
    },
    selectedProducts: {
      get() {
        const data = this.$store.state.order.mergeProducts
        console.log('selectedProducts  get', data)
        data.forEach(item => {
          item.storeProductAttrValues.forEach(subItem => {
            // 判断是否有新选择的商品
            if (subItem.uuid === undefined || subItem.uuid === '' || subItem.uuid === null) {
              this.isSelectNew = true
              this.submitDisabled = false
            }
          })
        })
        return data
      },
      set(v) {
        this.$store.state.order.mergeProducts = v
      }
    },
  },
  watch: {
    selectedProducts: {
      handler(newVal) {
        console.log('selectedProducts watch ooo', this.selectedProducts)
        console.log('selectedProducts', newVal)
        let am = 0
        newVal.forEach(item => {
          if (item.keyword !== undefined) {
            item.storeName = item.keyword
          }
          item.storeProductAttrValues.forEach(skuItem => {
            if (item.keyword !== undefined) {
              skuItem.storeName = item.keyword
            }
            let subAmount = Number(skuItem.number) * Number(skuItem.price)
            skuItem.amount = subAmount
            am += subAmount;
          })
        })
        this.amount = am * 100
        this.assignmentAfterSelect()
      },
      immediate: true,
    }
  },
  mounted() {
    this.container = this.$refs.container;
  },
  created() {
    const currentTime = new Date()
    this.orderDatetimeStr = `${currentTime.getFullYear()}/${currentTime.getMonth() + 1}/${currentTime.getDate()} ${currentTime.getHours()}:${currentTime.getMinutes()}`
    this.returnUuid = this.$route.query.returnUuid
    if (this.returnUuid !== null && this.returnUuid !== '' && this.returnUuid !== undefined) {
      this.navBarTitle = '退货详情'
      this.submitDisabled = true
      this.onLoadData()
    }
  },
  beforeDestroy() {
  },
  methods: {
    onConfirm(time) {
      this.orderDatetimeStr = `${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()} ${time.getHours()}:${time.getMinutes()}`
      this.orderDatetime = time
      this.showPicker = false
    },
    onLoadData() {
      returnService.getInfo(this.returnUuid).then(res => {
        if (res.status === 200) {
          this.editData = res.data
          console.log('index 加载数据结果为：', this.editData)
          this.orderUser = {
            username: res.data.data.realName,
            phone: res.data.data.userPhone,
            accountUserUid: res.data.data.accountUserUid
          }
          this.orderDatetimeStr = res.data.data.returnTime
          if (res.data.returnInfoVos !== undefined) {
            let union = [...this.selectedProducts, ...res.data.returnInfoVos]
            console.log('union', union)
            for (let i = 0, len = res.data.returnInfoVos.length; i < len; i++) {
              for (let j = 0, length = this.selectedProducts.length; j < length; j++) {
                if (res.data.returnInfoVos[i].productId === this.returnInfoVos[j].productId) {
                  union.splice(union.findIndex(item => item.productId === res.data.returnInfoVos[i].productId), 1)
                }
              }
            }
            this.selectedProducts = union
          } else {
            this.selectedProducts = []
          }
        }
      })
    },
    async assignmentAfterSelect() {
      await this.calculate4Sku()
      await this.calculate4Product()
      this.onChangeAmount()
    },
    calculate4Sku() {
      console.log('算商品 规格维度开始')
      // 算每个商品的整包数与散包数
      this.selectedProducts.forEach(item => {
        let itemNumber = 0;
        item.storeProductAttrValues.forEach(subItem => {
          itemNumber += Number(subItem.number)
          subItem.amount = Number(subItem.number) * Number(subItem.price)
        })
        item.number = itemNumber
      })
      console.log('算商品 规格维度结束', this.selectedProducts)
    },
    calculate4Product() {
      console.log('算商品 商品维度开始')
      // 算所有商品的整包数与散包数
      let pNumber = 0;
      this.selectedProducts.forEach(item => {
        pNumber += Number(item.number)
      })
      this.total = pNumber
      console.log('算商品 商品维度结束', this.selectedProducts)
    },
    onSkuGridEdit(data) {
      console.log('index 接受商品规格修改事件', data)
      console.log('index 所有商品：', this.selectedProducts)
      this.assignmentAfterSelect()
      if (data.uuid !== undefined && data.uuid !== '' && data.uuid !== null) {
        returnService.editSku(data).then(res => {
          if (res.status === 200) {
            this.$toast("修改成功")
          }
        })
      }
    },
    onClickLeft() {
      this.selectedProducts = []
      this.$router.push({path: '/return/list'})
    },
    toHome() {
      this.selectedProducts = []
      this.$router.push({path: '/index'})
    },
    onChangeAmount() {
      console.log('算商品 onChangeAmount 计算所有商品总价格', this.selectedProducts)
      let amount = 0;
      let number = 0;
      this.selectedProducts.forEach(item => {
        let subAmount = 0;
        let subNumber = 0;
        item.storeProductAttrValues.forEach(subItem => {
          subAmount += subItem.price * subItem.number;
          subNumber += subItem.number;
        })
        item.totalNum = subNumber
        item.totalPrice = subAmount
        amount += subAmount;
        number += subNumber;
      })
      this.amount = amount * 100;
      console.log('算商品 onChangeAmount 计算所有商品总价格结束', this.amount)
    },
    onShowPaymentBeforeSubmit() {
      this.isPopShow = true
    },
    resetData() {
      console.log('resetData')
      this.amount = 0
      this.selectedProducts = []
      $store.commit("SET_PRODUCTS", [])
      this.orderUser = {}
    },
    onSubmitForOrder() {
      console.log('提交前再计算一遍 商品规格维度 onSubmitForOrder')
      this.calculate4Sku()
      let accountOrderInfos = []
      this.selectedProducts.forEach(item => {
        item.storeProductAttrValues.forEach(tt => {
          accountOrderInfos.push(tt)
        })
      })
      if (accountOrderInfos.length < 1) {
        this.$toast("商品不能为空")
        return
      }
      let data = {
        accountUserUid: this.orderUser.id,
        realName: this.orderUser.username,
        returnTime: this.orderDatetime,
        userPhone: this.orderUser.phone,
        returnUuid: this.returnUuid,
        totalNum: this.total,
        totalPrice: this.amount,
        refundAmount: this.refundAmount,
        mark: this.mark,
        returnInfoList: accountOrderInfos
      }
      if (this.orderUser.id === undefined) {
        data.accountUserUid = this.orderUser.accountUserUid
      }
      console.log('onSubmitForOrder orderUser', this.orderUser)
      console.log('onSubmitForOrder data', data)

      if (data.accountUserUid === undefined) {
        this.$toast("请选择商家")
        return
      }
      Dialog.confirm({
        title: '确认修改此订单吗？',
        message: '请仔细检查～',
      })
        .then(() => {
          returnService.addReturn(data).then(res => {
            this.isPopShow = false
            this.resetData()
            Notify({type: 'success', message: '新增成功'});
          }).thenReject(res => {
            console.log(res)
            Notify({type: 'danger', message: res});
          }).fail(res => {
            console.log('fail')
          }).catch(res => {
            console.log('catch')
          })
        })
        .catch(() => {
          // on cancel
        });
    },
    showProductPopup() {
      let selectProductAfter = {
        redirect: this.$router.currentRoute.fullPath,
        type: 'order',
        userId: this.orderUser.id
      }
      $store.commit('setSelectProductAfter', selectProductAfter)
      this.$router.push({path: '/order/product/' + this.orderUser.id})
    },
    onSelectOrder() {
      let selectProductAfter = {
        redirect: this.$router.currentRoute.fullPath,
        type: 'order',
        userId: this.orderUser.id !== undefined ? this.orderUser.id :  this.orderUser.accountUserUid
      }
      $store.commit('setSelectProductAfter', selectProductAfter)
      if (this.orderUser.id !== undefined) {
        this.$router.push({path: '/selectOrder/' + this.orderUser.id})
      } else {
        this.$router.push({path: '/selectOrder/' + this.orderUser.accountUserUid})
      }
    },
    showPopup() {
      let selectEntityAfter = {
        redirect: this.$router.currentRoute.fullPath,
        type: 'order',
      }
      $store.commit('setSelectEntityAfter', selectEntityAfter)
      this.$router.push({path: '/order/user'})
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    onClickDelete() {
      Dialog.confirm({
        title: '确认删除吗？',
        message: '请仔细检查～',
      })
        .then(() => {
          returnService.deleteR(this.returnUuid).then(res => {
            if (res.status === 200) {
              this.$router.push({path: '/return/list'})
              this.$toast("删除成功")
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
